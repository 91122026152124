@font-face {
  font-family: 'Pretendard';
  src: local('Pretendard'), url('@/fonts/Pretendard-Regular.woff') format('woff'), url('@/fonts/Pretendard-Regular.woff2') format('woff2'),
    url('@/fonts/Pretendard-Regular.otf') format('opentype');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'Pretendard';
  src: local('Pretendard'), url('@/fonts/Pretendard-Light.woff') format('woff'), url('@/fonts/Pretendard-Light.woff2') format('woff2'),
    url('@/fonts/Pretendard-Light.otf') format('opentype');
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: 'Pretendard';
  src: local('Pretendard'), url('@/fonts/Pretendard-ExtraLight.woff') format('woff'), url('@/fonts/Pretendard-ExtraLight.woff2') format('woff2'),
    url('@/fonts/Pretendard-ExtraLight.otf') format('opentype');
  font-style: normal;
  font-weight: 200;
}

html,
body {
  font-weight: 300;
  word-break: keep-all;
  position: fixed;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

::selection {
  color: white;
  background: black;
}

input[type='range'] {
  -webkit-appearance: none;
  height: 2rem;
  background-color: transparent;
  cursor: pointer;
  filter: none;
  box-shadow: none;
  user-select: none;
}

.custom-slider {
  user-select: none;
  width: 100%;
}

.custom-slider-share {
  user-select: none;
  width: 100%;
}

.custom-range-track-share {
  width: calc(100% - 1rem);
  user-select: none;
}

input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  user-select: none;
  width: 100%;
  height: 0;
  cursor: pointer;
}
input[type='range']::-webkit-slider-thumb {
  user-select: none;
  -webkit-appearance: none;
  height: 2rem;
  width: 2rem;
  border-radius: 2rem;
  border: 1px solid black;
  background-color: white;
  cursor: pointer;
  margin-top: -0.75rem;
  z-index: 10;
  box-shadow: none;
}
.custom-range-track {
  width: calc(100% - 4rem);
}

[contenteditable='true']:empty:before {
  content: attr(placeholder);
  display: block; /* For Firefox */
  color: rgb(177, 177, 177);
}

#div-three {
  background-color: #f5ebe1;
}

@media print {
  body,
  #__next {
    overflow: visible !important;
  }
  @page {
    margin: 0;
    size: A4 landscape;
  }
  body {
    padding-top: 36px;
    padding-bottom: 36px;
  }
  #cvs-three {
    position: absolute;
    top: calc(50% - 36px);
    left: 36px;
    transform: translateY(-50%);
    background-color: rgb(245, 235, 225);
  }
  #div-printText {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-left: 36px;
    margin-right: 36px;
    margin-bottom: 72px;
    padding-top: 16px;
    border-top: 1px solid black;
    width: 308px;
  }
}

.btn-social {
  border-radius: 10rem;
  height: 2.75rem;
  padding: 0 1rem;
  border: 1px solid black;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.currentPath::after {
  content: '•';
  position: absolute;
  line-height: 0.5;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.about-join {
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 33.333333%;
  height: 33.333333%;
  border-radius: 10rem;
  font-weight: 200;
  align-items: center;
  justify-content: center;
  text-align: center;
  animation: rotate 20s linear infinite;
  background-color: black;
  color: white;
}

.main-join {
  display: flex;
  border-radius: 10rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  animation: rotate 20s linear infinite;
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  border: solid 1px black;
}

.about-join:hover {
  background-color: white;
  color: black;
  border: solid 1px black;
  animation-play-state: paused;
}

.main-join:hover {
  background-color: black;
  color: white;
  animation-play-state: paused;
}

.creditLink {
  display: inline-block;
}
.creditLink:hover {
  text-decoration: underline;
}

.dot {
  cursor: crosshair;
}

.scroll {
  position: absolute;
  border-radius: 0.5rem;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border: 1px solid black;
  pointer-events: none;
}

@media (hover: hover) {
  .btn-social:hover > svg {
    filter: invert(1);
  }
  .btn-social:hover {
    background-color: black;
    color: white;
  }
}

@media not print {
  body,
  #__next {
    display: hidden;
  }
}

@media (min-width: 768px) {
  input[type='range'] {
    height: 3rem;
  }
  input[type='range']::-webkit-slider-thumb {
    height: 3rem;
    width: 3rem;
    margin-top: -1.75rem;
  }
  .custom-range-track {
    width: calc(100% - 6rem);
  }
  .custom-range-track-share {
    width: calc(100% - 2rem);
  }
}
